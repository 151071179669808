import { useReducer, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from './AuthContext';
import { AuthReducer, REQUEST_LOGIN, LOGIN_SUCCESS, LOGOUT } from './AuthReducer';
import useHttp, { GET, POST } from '../../hooks/http/useHttp';
import Environment from '../../environment';

const authInitialState = {
  username: '',
  displayName: '',
  isAuth: false,
  isLoading: true,
  errorMessage: null
};

const AuthProvider = (props) => {
  const history = useHistory();
  const { sendRequest } = useHttp();

  const [authState, dispatchAuth] = useReducer(AuthReducer, authInitialState);
  const { AUTH_API } = Environment;

  const checkIsUserExpired = useCallback(() => {
    sendRequest({
      url: AUTH_API.IS_USER_EXPIRED.path,
      method: GET
    })
      .then((response) => {
        dispatchAuth({
          type: LOGIN_SUCCESS,
          username: response.data.username,
          displayName: response.data.displayName
        });
        history.replace(history.location.path);
      })
      .catch(() => {
        dispatchAuth({
          type: LOGOUT
        });
      });
  }, [history, sendRequest]);

  useEffect(() => {
    if (history.location.pathname !== '/') {
      checkIsUserExpired();
    }
  }, [checkIsUserExpired]);

  const loginHandler = useCallback(
    (body) => {
      localStorage.removeItem('userToken');
      dispatchAuth({ type: REQUEST_LOGIN });
      return sendRequest({
        url: AUTH_API.LOGIN.path,
        method: POST,
        body
      })
        .then((response) => {
          dispatchAuth({ type: LOGIN_SUCCESS, username: response.data.username, displayName: response.data.displayName });
          history.replace('/panel');
        })
        .catch((error) => {
          dispatchAuth({ type: LOGOUT });
          throw error;
        });
    },
    [history, sendRequest]
  );

  const logoutHandler = useCallback(() => {
    dispatchAuth({ type: LOGOUT });
    localStorage.removeItem('userToken');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        username: authState.username,
        displayName: authState.displayName,
        isAuth: authState.isAuth,
        isLoading: authState.isLoading,
        login: loginHandler,
        logout: logoutHandler,
        errorMessage: authState.errorMessage,
        dispatchAuth
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
