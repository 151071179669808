import { useCallback, useReducer, useEffect, useContext } from 'react';
import { RouteReducer, SET_ALL, SET_CONTENT_KEY, CLEAR } from './RouteReducer';
import RouteContext from './RouteContext';
import AuthContext from '../auth/AuthContext';
import { AuthPageComponents } from '../../containers/App/Router/AllPages';
import convertRoutesToSidebarNavigation from '../../utils/sidebarNavigationUtil';
import useHttp, { GET } from '../../hooks/http/useHttp';
import Environment from '../../environment';

const initialState = {
  authRoutes: [],
  permissions: [],
  sidebarNavigations: []
};

const RouteProvider = (props) => {
  const [routeState, dispatchRoute] = useReducer(RouteReducer, initialState);
  const { isAuth } = useContext(AuthContext);
  const { sendRequest } = useHttp();
  const { AUTH_API } = Environment;
  const getRoutesFromApi = useCallback(() => {
    sendRequest({
      url: AUTH_API.GET_ROUTES.path,
      method: GET
    })
      .then((routesResponse) => {
        const definedComponents = Object.values(routesResponse.data).filter((value) => AuthPageComponents[value.authPageComponentKey] !== undefined);

        const authRoutes = Object.values(definedComponents)
          .filter((value) => AuthPageComponents[value.authPageComponentKey].path !== null)
          .map((value) => AuthPageComponents[value.authPageComponentKey]);

        const sidebarNavigations = convertRoutesToSidebarNavigation(authRoutes);

        const permissions = Object.fromEntries(definedComponents.map((value) => [value.authPageComponentKey, true]));
        dispatchRoute({
          type: SET_ALL,
          authRoutes,
          sidebarNavigations,
          permissions
        });
      })
      .catch(() => {
        dispatchRoute({ type: CLEAR });
      });
  }, [sendRequest]);

  useEffect(() => {
    if (isAuth) {
      getRoutesFromApi();
    } else {
      dispatchRoute({ type: CLEAR });
    }
  }, [isAuth, getRoutesFromApi]);

  const changeContentKeyHandler = useCallback(() => {
    dispatchRoute({ type: SET_CONTENT_KEY });
  }, []);

  return (
    <RouteContext.Provider
      value={{
        contentKey: routeState.contentKey,
        changeContentKey: changeContentKeyHandler,
        getRoutes: getRoutesFromApi,
        authRoutes: routeState.authRoutes,
        sidebarNavigations: routeState.sidebarNavigations,
        permissions: routeState.permissions
      }}
    >
      {props.children}
    </RouteContext.Provider>
  );
};

export default RouteProvider;
