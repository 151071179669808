import configHarbiyeHukuk from './harbiyeHukuk/configHarbiyeHukuk';
import configAkademikHukuk from './akademikHukuk/configAkademikHukuk';

const configMap = [
  { configName: 'akademikHukuk', config: configAkademikHukuk },
  { configName: 'harbiyeHukuk', config: configHarbiyeHukuk }
];

const { config } = configMap.filter((configMapElement) => configMapElement.configName === process.env.REACT_APP_ENV_CONFIG)[0];

const { faq, logoColor, logoTransparent, appPort } = config;

export { faq, logoColor, logoTransparent, appPort };
export default config;
