import React, { useCallback } from 'react';
import { CHeader, CToggler, CHeaderBrand, CHeaderNav, CDropdown, CDropdownToggle } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import TopbarProfile from './TopbarProfile';

const Topbar = ({ showHandler, username, logoutHandler }) => {
  const toggleFullScreenButtonHandler = useCallback(() => {
    if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }, []);

  const toggleSidebar = () => {
    showHandler();
  };

  const toggleSidebarMobile = () => {
    showHandler();
  };

  return (
    <CHeader>
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav className="d-md-down-none mr-auto"></CHeaderNav>
      <CHeaderNav className="px-3">
        <CDropdown inNav className="c-header-nav-item mx-2 d-md-down-none">
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <i className="bx bx-fullscreen font-size-22" onClick={toggleFullScreenButtonHandler} />
          </CDropdownToggle>
        </CDropdown>
        <TopbarProfile username={username} logoutHandler={logoutHandler} />
      </CHeaderNav>
    </CHeader>
  );
};

export default Topbar;
