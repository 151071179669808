import { createContext } from 'react';

const AuthContext = createContext({
  username: '',
  displayName: '',
  isAuth: false,
  isLoading: false,
  errorMessage: null,
  login: () => {},
  logout: () => {},
  dispatchAuth: () => {}
});

export default AuthContext;
