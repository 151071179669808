import React, { useContext } from 'react';
import Loader from '../../shared/components/loader/Loader';
import LoaderContext from '../../context/loader/LoaderContext';

const useFullPageLoader = () => {
  const { loading, setLoading } = useContext(LoaderContext);
  return {
    loaderComponent: loading ? <Loader /> : null,
    showLoader: () => setLoading(true),
    hideLoader: () => setLoading(false),
    loaderState: loading
  };
};

export default useFullPageLoader;
