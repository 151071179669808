const ErrorReducer = (prevState, action) => {
  switch (action.type) {
    case 'CLEAR':
      return { ...prevState, hasError: false, message: null, code: null, id: null };
    case 'SET_ERROR':
      return { ...prevState, hasError: true, message: action.message, code: action.code, id: action.id };
    default:
      return { ...prevState };
  }
};

export default ErrorReducer;
