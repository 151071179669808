import { Suspense, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import PrivateRouter from './PrivateRouter';
import AuthContext from '../../../context/auth/AuthContext';
import RouteContext from '../../../context/route/RouteContext';
import { NonAuthPageComponents } from './AllPages';
import Layout from '../../layout/Layout';
import OrionHoc from '../../../shared/components/hoc/OrionHoc';
import ErrorDialog from '../../../shared/components/error/ErrorDialog';
import ToastContext from '../../../context/toast/ToastContext';
import useFullPageLoader from '../../../hooks/loader/useFullPageLoader';

const nonAuthRoutes = [
  NonAuthPageComponents.Login,
  NonAuthPageComponents.ClientHomePage,
  NonAuthPageComponents.Page404,
  NonAuthPageComponents.Page503
];

const Router = () => {
  const toast = useContext(ToastContext);
  const { isAuth, isLoading } = useContext(AuthContext);
  const { authRoutes } = useContext(RouteContext);
  const { loaderComponent } = useFullPageLoader();

  const nonAuthRoutesRender = nonAuthRoutes.map((route) => (
    <Route exact={route.exact} path={route.path} component={route.component} key={route.path} />
  ));

  return (
    <OrionHoc>
      <Toast ref={toast} />
      <ErrorDialog />
      {loaderComponent}
      <Suspense fallback={null}>
        <Switch>
          {nonAuthRoutesRender}
          <PrivateRouter component={Layout} isAuth={isAuth} isLoading={isLoading} authRoutes={authRoutes} />
        </Switch>
      </Suspense>
    </OrionHoc>
  );
};

export default Router;
