import { GroupIcons } from '../containers/App/Router/AllPages';
import { getGroupMenuOrder, groupBy } from './arrayUtil';

const convertRoutesToSidebarNavigation = (authRoutes) => {
  const sidebarNavigations = [];
  const menu = {
    _tag: 'CSidebarNavTitle',
    _children: ['Menü']
  };
  sidebarNavigations.push(menu);

  const sideMenuGroups = Object.entries(
    groupBy(
      authRoutes.filter((route) => route.showSidebar),
      'groupName'
    )
  ).filter((item) => item[0] !== 'null');

  const orderedSideMenuGroups = [];
  sideMenuGroups.forEach((item) => {
    orderedSideMenuGroups[getGroupMenuOrder(item[0])] = item;
  });

  orderedSideMenuGroups.forEach(([key, values]) => {
    if (key === 'Menü') {
      values.forEach((value) => {
        sidebarNavigations.push({
          _tag: 'CSidebarNavItem',
          name: <span className="menu-title">{value.pageName}</span>,
          to: value.path,
          icon: value.icon
        });
      });
    } else {
      const children = [];
      values.forEach((value) => {
        children.push({
          _tag: 'CSidebarNavItem',
          name: <span className="menu-title">{value.pageName}</span>,
          to: value.path,
          icon: value.icon
        });
      });
      sidebarNavigations.push({
        _tag: 'CSidebarNavDropdown',
        name: <span className="menu-title">{GroupIcons[key].sidebarName}</span>,
        to: '/#',
        icon: GroupIcons[key].icon,
        _children: children
      });
    }
  });

  return sidebarNavigations;
};

export default convertRoutesToSidebarNavigation;
