/* eslint-disable no-param-reassign */
export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getGroupMenuOrder = (menu) => {
  switch (menu) {
    case 'Menü':
      return 0;
    case 'Endeks':
      return 1;
    case 'ModelData':
      return 2;
    case 'Report':
      return 3;
    case 'Admin':
      return 4;
    default:
      return 0;
  }
};

export const removeItemAll = (arr, value) => {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      i += 1;
    }
  }
  return arr;
};

export const removeItemAllByIds = (arr, ids) => {
  let i = 0;
  let j = 0;
  while (i < arr.length) {
    while (j < ids.length) {
      if (arr[i].id === ids[j]) {
        arr.splice(i, 1);
        j += 1;
      } else {
        i += 1;
      }
    }
  }
  return arr;
};

export const removeItemOnce = (arr, value) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const getDifferenceOfTwoArrays = (firstArray, secondArray) => {
  return firstArray.filter(
    (firstArrayElement) => !secondArray.some((secondArrayElement) => JSON.stringify(firstArrayElement) === JSON.stringify(secondArrayElement))
  );
};

export const onlyUnique = (value, index, self) => self?.indexOf(value) === index;

export const descending = (a, b) => b - a;

export const ascending = (a, b) => a - b;
