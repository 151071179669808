import { useCallback, useContext } from 'react';
import ToastContext from '../../context/toast/ToastContext';

const useToast = () => {
  const toast = useContext(ToastContext);

  const showToast = useCallback(
    ({ severity, summary, showDetail, errorMessage, life }) => {
      toast.current.show({
        severity,
        summary,
        detail:
          showDetail === true ? (
            <div>
              <h5>Hata Mesajı : {errorMessage}</h5>
            </div>
          ) : null,
        life
      });
    },
    [toast]
  );

  return { showToast };
};

export default useToast;
