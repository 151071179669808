const REQUEST_LOGIN = 'REQUEST_LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT = 'LOGOUT';

const AuthReducer = (currentAuthState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN:
      return {
        ...currentAuthState,
        isLoading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...currentAuthState,
        username: action.username,
        displayName: action.displayName,
        isAuth: true,
        isLoading: false
      };
    case LOGOUT:
      return {
        username: '',
        displayName: '',
        isAuth: false,
        isLoading: false,
        errorMessage: null
      };
    default:
      return { ...currentAuthState };
  }
};

export { AuthReducer, LOGOUT, LOGIN_SUCCESS, REQUEST_LOGIN };
