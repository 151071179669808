import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { locale, addLocale } from 'primereact/api';
import AuthProvider from '../../context/auth/AuthProvider';
import Router from './Router/Router';
import RouteProvider from '../../context/route/RouteProvider';
import LoaderProvider from '../../context/loader/LoaderProvider';
import ToastProvider from '../../context/toast/ToastProvider';
import ErrorProvider from '../../context/error/ErrorProvider';
import ScrollToTop from './ScrollToTop';
import MessageProvider from '../../context/message/MessageProvider';
import 'bootstrap/dist/js/bootstrap.bundle';

function setLocaleSettings() {
  addLocale('tr', {
    accept: 'Evet',
    reject: 'Hayır',
    choose: 'Seç',
    upload: 'Yükle',
    cancel: 'İptal',
    dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
    dayNamesShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
    dayNamesMin: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
    today: 'Bugün',
    clear: 'Temizle',
    weekHeader: 'Hb',
    firstDayOfWeek: 0,
    dateFormat: 'mm/dd/yy',
    weak: 'Zayıf',
    medium: 'Orta',
    strong: 'Güçlü',
    passwordPrompt: 'Bir parola girin',
    apply: 'Arama'
  });
  locale('tr');
}

const App = () => {
  useEffect(() => {
    setLocaleSettings();
  }, []);

  return (
    <div className="scrollbar scrollbar-primary">
      <BrowserRouter>
        <LoaderProvider>
          <ErrorProvider>
            <MessageProvider>
              <ToastProvider>
                <AuthProvider>
                  <ScrollToTop>
                    <RouteProvider>
                      <Router />
                    </RouteProvider>
                  </ScrollToTop>
                </AuthProvider>
              </ToastProvider>
            </MessageProvider>
          </ErrorProvider>
        </LoaderProvider>
      </BrowserRouter>
    </div>
  );
};
export default App;
