import { appPort } from './config/config';

export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `${window.location.protocol}//${window.location.hostname}`
    : `${window.location.protocol}//${window.location.hostname}:${appPort}`;

const Environment = {
  AUTH_API: {
    LOGIN: {
      path: `${BASE_URL}/api/auth/login`,
      mapping: 'POST'
    },
    LOGOUT: { path: `${BASE_URL}/api/auth/logout`, mapping: 'GET' },
    IS_USER_EXPIRED: { path: `${BASE_URL}/api/auth/isUserExpired`, mapping: 'GET' },
    GET_ROUTES: { path: `${BASE_URL}/api/route/get-user-routes`, mapping: 'GET' }
  },
  USER_API: {
    FIND_ALL: { path: `${BASE_URL}/api/users/find-all`, mapping: 'GET' },
    SAVE: { path: `${BASE_URL}/api/users/save`, mapping: 'POST' },
    DELETE_BY_ID: { path: `${BASE_URL}/api/users/delete-by-id`, mapping: 'DELETE' },
    UPDATE: { path: `${BASE_URL}/api/users/update`, mapping: 'PUT' },
    UPDATE_PASSWORD: { path: `${BASE_URL}/api/users/update-password`, mapping: 'PUT' },
    FIND_BY_ID: { path: `${BASE_URL}/api/users/find-by-id`, mapping: 'GET' }
  },
  APPOINTMENT_API: {
    GET_AVAILABLE_TIMES_BY_DATE: { path: `${BASE_URL}/api/appointment/available/times`, mapping: 'GET' },
    SAVE: { path: `${BASE_URL}/api/appointment/save`, mapping: 'POST' },
    APPROVE: { path: `${BASE_URL}/api/appointment/save/approve`, mapping: 'POST' },
    FIND_ALL: { path: `${BASE_URL}/api/appointment`, mapping: 'GET' },
    MARK_APPOINTMENT_AS_READ: { path: `${BASE_URL}/api/appointment/markAsRead`, mapping: 'GET' },
    MARK_APPOINTMENT_AS_UN_READ: { path: `${BASE_URL}/api/appointment/markAsUnRead`, mapping: 'GET' },
    GET_COMMUNICATION_TYPES: { path: `${BASE_URL}/api/appointment/communicationTypes`, mapping: 'GET' }
  },
  SCHEDULED_EVENT_API: {
    SAVE: { path: `${BASE_URL}/api/scheduledEvent`, mapping: 'POST' }
  },
  SUBJECT_API: {
    GET_ALL: { path: `${BASE_URL}/api/subject`, mapping: 'GET' },
    SAVE: { path: `${BASE_URL}/api/subject`, mapping: 'POST' },
    UPDATE: { path: `${BASE_URL}/api/subject`, mapping: 'PUT' },
    DELETE: { path: `${BASE_URL}/api/subject`, mapping: 'DELETE' },
    FIND_BY_ID: { path: `${BASE_URL}/api/subject/find-by-id`, mapping: 'GET' }
  },
  FILE_API: {
    DOWNLOAD: { path: `${BASE_URL}/api/files`, mapping: 'GET' }
  }
};

export default Environment;
