import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from '../../shared/axios/Axios';
import ErrorContext from '../../context/error/ErrorContext';
import useToast from '../toast/useToast';
import useFullPageLoader from '../loader/useFullPageLoader';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const CONTENT_TYPE_JSON = 'application/json';
export const CONTENT_TYPE_MULTIPART_FORM_DATA = 'multipart/form-data';

const redirect = (history, url, state) => {
  if (process.env.NODE_ENV === `production`) {
    history.replace(url, state);
  }
};

const useHttp = () => {
  const { showLoader, hideLoader } = useFullPageLoader();
  const history = useHistory();

  const { dispatchErrorState } = useContext(ErrorContext);

  const { showToast } = useToast();

  const clear = hideLoader;

  const summaryForHttpStatus = (status) => {
    if (status === 400) {
      return 'Yanlış İstek';
    }
    if (status === 401) {
      return 'Yetkisiz İstek';
    }
    if (status === 403) {
      return 'Yasaklı İstek';
    }
    if (status === 408) {
      return 'Bağlantı Zaman Aşımı';
    }

    return 'Sunucu Hatası';
  };

  const sendRequest = useCallback(
    async ({
      url,
      method,
      body = null,
      contentType = CONTENT_TYPE_JSON,
      isErrorHandle = true,
      queryParams,
      isFullPageLoaderActive = true,
      showSuccessToast = false,
      successMessageTitle = null,
      showErrorToast = true,
      errorMessageTitle = null,
      responseType = null
    }) => {
      if (isFullPageLoaderActive) showLoader();
      const config = {
        url,
        method,
        data: body,
        headers: {
          'Content-Type': contentType,
          Authorization: localStorage.getItem('userToken')
        },
        withCredentials: true,
        params: queryParams
      };

      if (responseType) config.responseType = responseType;
      const httpResponse = await Axios(config)
        .then((response) => {
          if (isFullPageLoaderActive) hideLoader();
          if (showSuccessToast) {
            showToast({ severity: 'success', summary: successMessageTitle || 'İşlem Başarılı', showDetail: false, life: 3000 });
          }
          localStorage.setItem('userToken', response.headers?.token);
          return response;
        })
        .catch((error) => {
          if (isFullPageLoaderActive) hideLoader();
          if (!error.response) {
            showToast({
              severity: 'error',
              summary: 'Ağ veya Sunucu hatası',
              showDetail: false,
              life: 5000
            });
            redirect(history, '/sunucu-hatasi', null);
          } else {
            if (showErrorToast) {
              showToast({
                severity: error?.response?.status === 401 ? 'warn' : 'error',
                summary: errorMessageTitle || summaryForHttpStatus(error?.response?.status),
                showDetail: error?.response !== undefined,
                errorMessage: error?.response?.data?.message,
                errorTime: error?.response?.data?.timestamp,
                life: 5000
              });
            }
            if (error.response.status === 401) {
              redirect(history, '/giris');
              localStorage.removeItem('userToken');
            } else if (error.response.status === 403) {
              localStorage.removeItem('userToken');
              redirect(history, '/giris');
            } else if (error.response.status === 500 && isErrorHandle) {
              dispatchErrorState({
                type: 'SET_ERROR',
                message: error.response?.data.message,
                code: error.response.status,
                id: error.response?.data.errorId
              });
            }
          }
          throw error;
        });
      return httpResponse;
    },
    [dispatchErrorState, history]
  );

  return {
    sendRequest,
    clear
  };
};

export default useHttp;
